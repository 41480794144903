document.addEventListener('DOMContentLoaded', function () {
    $('.secureCountriesList__btn').click(function () {
        if ($(this).hasClass('active')) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 175);
        }

        $(this).toggleClass('active');
        $('.secureSectionBlock').toggleClass('secureSectionBlock__active');
        $('.secureCountriesListBlock').toggleClass('active');
        $('.secureCountriesListBlockIn').slideToggle(30);
    });
});
